
import { Col, Row } from "react-bootstrap"
import "./Information.css"

import candan5 from "../asset/candan5.jpg"
import candan6 from "../asset/IMG-7010.jpg"
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import React, { useState } from "react"
import Mairie from "./mairie/Mairie";
import Soiree from "./soiree/Soiree"
import Henne from "./henne/Henne"
import Hebergement from "./hebergement/Hebergement";
import Transport from "./transport/Transport";
// ..
AOS.init();

const Information = () => (<>
    <article>
        <Mairie/>
        <section>
            <Row style={{marginTop:5+"vh",marginBottom:5+"vh"}} className="justify-content-around">
                <img src={candan6} className="in-between-pic" alt="Le Henné"/>
            </Row>
        </section>
        
        <Henne/>

        <section>
            <Row style={{marginTop:5+"vh",marginBottom:5+"vh"}} className="justify-content-around">
                {/* <img src={candan5} alt="La soirée"/> */}
                <img src={candan5} className="in-between-pic" alt="La soirée"/>

            </Row>
        </section>
        <Soiree/>
        <Hebergement/>
        <Transport/>
    </article></>
)

export default Information