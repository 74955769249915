import "./Header.css"

// import moment from "moment"
import homePic from "../asset/Eden&Ilan2Plage.jpg"

import React, { useState } from "react"
// import moment from "moment"
import { Col, Row } from "react-bootstrap"
import { useCountdown } from "../tools/countdown"


const Header = () => {

    const targetDate = new Date("2024/08/28")
    targetDate.setHours(16)
    targetDate.setMinutes(30)

    const [days, hours, minutes, seconds] = useCountdown(targetDate)

    return (

            <header>
                <Col md="12">

                {/* {valueDate} */}
                <div>

                    <Row className="justify-content-around">
                        <img src={homePic} alt="Eden et Ilan." id="firstPic"/>
                    <Row className="justify-content-around  display-date-pos" >
                        <Col md={8} sm={12}>
                            <Row className="justify-content-center " md={12} sm={12}>

                                <Col md={3} sm={3} xs={3} className="display-date  align-items-center">
                                    <Col>
                                        {days} 
                                        <p className="display-date-p ">
                                            jours
                                        </p>
                                    </Col>
                                </Col>
                                <Col md={3} sm={3} className="display-date align-items-center">
                                    <Col>
                                        {hours} 
                                        <p className="display-date-p ">
                                            heures
                                        </p>
                                    </Col>
                                </Col>
                                <Col md={3} sm={3} className="display-date  align-items-center">
                                    <Col>
                                    {minutes} 
                                    <p className="display-date-p ">
                                        minutes
                                    </p>
                                    </Col>  
                                </Col>
                                <Col md={3} sm={3} className="display-date  align-items-center">
                                    <Col>
                                        {seconds} 
                                        <p className="display-date-p ">
                                            secondes
                                        </p>
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    </Row>
                </div>
                </Col>
                {/* <Moment duration={new Date()} date={date}  format="DD/MM/YYYY HH:mm:ss" interval="1000"></Moment> */}
            </header>
    )
}

export default Header