import { Button, Col, Row } from "react-bootstrap"
import "../Information.css"

import logo from "../../asset/logo_candan_red.png"
import hebreux from "../../asset/hebreux-1.png"
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import React, { useState } from "react"


AOS.init();

window.onload = function(){
	setTimeout(function () {
		var x = document.querySelectorAll(".yourNexthigherObject");
		for (var i = 0; i < x.length; i++) {
			x[i].style.display = "block";
			x[i].style.overflowX = "hidden";
		}
	}, 500);
}

const Soiree = () => {
    return( <section className="">
        <Row className="justify-content-around" id="houppa">
            {/* <Col md={"auto"}> */}
                <Col  md="auto" xs="auto" className="titre">
                    La Houppa
                </Col>
            {/* </Col> */}
        </Row>
        <Row className="justify-content-center">
            {/* <Col md={"auto"}> */}
                <img src={hebreux} alt="" className="hebreux-phrase"/>
            {/* </Col> */}
        </Row>
        <Row className="justify-content-between" >
            <Col md={4} sm={6} xs={6} className="famille" data-aos="fade-right" data-aos-duration="1000">
                    Mme et Mr <br />
                    {/* Madame et Monsieur <br /> */}
                    Cécile & William Chaouat <br />
                    Mme et Mr <br />
                    {/* Madame et Monsieur <br /> */}
                    Michèle & Gérard Boukhris <br />
            </Col>
            <Col md={4} sm={6} xs={6}  className="yourNexthigherObject">
                <div className="famille justify-content-around text-right" data-aos="fade-left" data-aos-duration="1000">
                    <p className="text-right">
                        Mme <br></br>
                    Rachel Dahan<br />
                    Mr<br></br> 
                    Jean Cohen Solal
                    </p>
                </div>
            </Col>
        </Row>
        <Row className="justify-content-between ">
            <Col md={4} xs={6} className="famille" data-aos="fade-right" data-aos-duration="1000">
                    {/* Madame et Monsieur <br /> */}
                    Mme et Mr <br />
                    Estelle & Dove Chaouat
            </Col>
            <Col md={4} xs={6} className="yourNexthigherObject">
                <div className="famille" data-aos="fade-left" data-aos-duration="1000">
                    {/* Madame et Monsieur <br /> */}
                    Mme et Mr <br />
                    Nathalie & Gabriel Dahan
                </div>
            </Col>
        </Row>
        <Row className="justify-content-around logo">
            <img src={logo} alt="logo" style={{ width: 50 + "px", height: "auto" }}  data-aos="zoom-in"  data-aos-duration="1000"/>
        </Row>
        <Row className="justify-content-around message">
            <Col md="auto" xs={10}  data-aos="zoom-in" data-aos-duration="2000">
                <p>
                    Ont l'immense joie de vous faire part du mariage de leurs petit-enfants et enfants
                </p>
            </Col>
        </Row>
            <Row className="justify-content-around marie" >
                <Col md="auto" xs="auto"  data-aos="zoom-in" data-aos-delay="5s">
                    <p>
                        Eden & Ilan
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-around message">
                <Col md="auto" xs="auto"  data-aos="zoom-in" data-aos-delay="5s">
                    <p>
                        Seraient honorés de votre présence à la cérémonie religieuse qui sera célébrée
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-around infos">
                <Col md="auto" xs="auto"  data-aos="zoom-in" data-aos-delay="5s">
                    <p>
                        Le Mercredi 28 août 2024 à 17h
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-around message">
                <Col md="auto" xs="auto"  data-aos="zoom-in" data-aos-delay="5s">
                    {/* <p>
                        Cérémonie religieuse qui sera célébrée au
                    </p> */}
                </Col>
            </Row>
            <Row className="justify-content-around infos" >
                <Col md="auto" xs="auto"  data-aos="zoom-in" data-aos-delay="5s">
                    <p>
                        Domaine du Mont Leuze<br />
                        600 Chemin du Mont Leuze<br />
                        06230, Villefranche-sur-Mer
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-around message">
                <Col md="auto" xs="auto"  data-aos="zoom-in" data-aos-delay="5s">
                    <p>
                        Une réception suivra
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-around " style={{textAlign:"center"}}>
                <Col md="auto" xs="auto"  data-aos="zoom-in" data-aos-delay="5s">
                <p>
                    Des cars seront mis à votre dispositon pour vous rendre sur le lieu de la réception.
                </p>
                <p>

                    Départ des cars à <strong>15h45
                    </strong> du <strong><a className="itineraire" href="https://www.waze.com/fr/live-map/directions/fr/provence-alpes-cote-dazur/villefranche-sur-mer/domaine-du-mont-leuze?navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location&to=place.ChIJi5Hx3drEzRIR-VoPoaGGJpc&from=place.ChIJF-MZV__RzRIRJPLBkxDAUpo">Radisson Hotel Nice Airport
                    </a></strong> et du <strong><a className="itineraire" href="https://www.waze.com/fr/live-map/directions/fr/provence-alpes-cote-dazur/villefranche-sur-mer/domaine-du-mont-leuze?navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location&to=place.ChIJi5Hx3drEzRIR-VoPoaGGJpc&from=place.ChIJPTFgnf3QzRIR-wvM2KkdDqE">Nemea Appart'Hotel Nice Riviera.
                        </a></strong>
                </p>
                </Col>
            </Row>
            
        <Row className="justify-content-center solenel">
            <Col md="auto" xs="auto"  data-aos="zoom-in" data-aos-delay="5s">
                <p>
                    En ce jour solennel, nous avons une pensée toute particulière pour nos grand-parents, papi Prosper, mamie Gladys et mamie Suzette.
                </p>
            </Col>
        </Row>
        
        <Row className="justify-content-around">
            <Col className="solenel-honneur" md="auto"  xs="auto" data-aos="zoom-in"  data-aos-duration="1000">
                <Button  className="itinéraire" href="https://ul.waze.com/ul?place=Ei5MZSBEb20uIGRlcyBDYWxhbnF1ZXMsIDEzMDA5IE1hcnNlaWxsZSwgRnJhbmNlIi4qLAoUChIJ89xYSnK4yRIRlTnLy0ElMDoSFAoSCTNT2kRDv8kSESACl_2lGQgE&ll=43.22505600%2C5.40193890&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location">Itinéraire</Button>
            </Col>
        </Row>
    </section>)
}

export default Soiree