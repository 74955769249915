import { Button, Container, Form, Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap"
import logo from "./../asset/logo_candan_red.png"
import "./Menu.css"
import React from "react"

const Menu = () => {

    return(
        <Navbar  md={3} sm={12} xs={12} expand="sm" className="navbar-style-wedding-content justify-content-between" >
            
            <Container fluid>
            <Navbar.Brand >
                <img src={logo} alt="Candice & Dan" style={{width:"auto",height:10+"vh"}}/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={"expand-items-sm"}  />
                <Navbar.Collapse id="expand-items-sm" className="justify-content-end">
    
                    <Nav style={{textAlign:"center"}} className="navbar-style-wedding-bar">
                        {/* <Nav.Link href="#mairie" className="navbar-style-wedding-items">Mairie</Nav.Link> */}
                        <Nav.Link href="#henne" className="navbar-style-wedding-items">Henné</Nav.Link>
                        <Nav.Link href="#houppa" className="navbar-style-wedding-items">Houppa</Nav.Link>
                        <Nav.Link href="#hebergement" className="navbar-style-wedding-items">Logements</Nav.Link>
                        <Nav.Link href="#transport" className="navbar-style-wedding-items">Transport</Nav.Link>
                        <Nav.Link href="#votre-reponse" className="navbar-style-wedding-items">Votre réponse</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
          </Container>
        </Navbar>
    )
}

export default Menu