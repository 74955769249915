import React, { useEffect, useState } from "react"

import {Form,  FloatingLabel, Row, Col, Button, Toast, Modal, ModalBody } from "react-bootstrap"
import "./Formulaire.css"
import axios from "axios"

const Formulaire = () => {
    const [results, setResult] = useState({presenceHenne:1,presenceSoiree:1})
    const [showHenne, setShowHenne] = useState(1)
    const [showSoiree, setShowSoiree] = useState(1)
    const [message, setMessage] = useState("")
    const [showMessage, setShowMessage] = useState(false)


    const onChangeForm = (event) => {
        results[event.target.id] = event.target.value 
        if(results.presenceHenne==0){
            results.nombreAdulteHenne = 0
            results.nombreEnfantHenne = 0
            results.nombreCarHenne = 0
            setShowHenne(0)
        }else{
            setShowHenne(1)
        }
        if(results.presenceSoiree==0){
            setShowSoiree(0)
            results.nombreAdulteSoiree = 0
            results.nombreEnfantSoiree = 0
            results.nombreCarSoiree = 0
        }else{
            setShowSoiree(1)
        }
        setResult(results)
    }

    useEffect(()=>{
    }, [results.presenceSoiree,results.presenceHenne])

    const onSubmitForm = (event) =>{
        event.preventDefault()

        // fetch("http://shanaetvalentin.com/edenetilan", {
        //     method:"POST",
        //     headers : { 
        //         'Content-Type': 'application/json',
                
        //     },
        //     body : JSON.stringify(results)
        // })axios.post("https://www.data.andn-services.fr/familly/add", results)
        // axios.post("http://edenetilan.fr/back/add", results).then((value)=>{
        axios.post("https://www.data.andn-services.fr/familly/add", results).then((value)=>{
            console.log(value)
            setShowMessage(true)
            setMessage("Votre réponse a bien été enregistré.")
                
        }).catch((reason)=>{
            console.log(reason)
            setShowMessage(true)
            setMessage("Erreur dans le formulaire.")
        })
        // axios.post("https://www.data.andn-services.fr/familly/add", results).then((r, err)=>{
        //     if(err) {
        //         setShowMessage(true)
        //         setMessage("Erreur dans le formulaire.")
        //     }else{
                
        //         setShowMessage(true)
        //         setMessage("Votre réponse a bien été enregistré.")
                
        //     }
        // })

    }

    return (
        <>
        <Row id="votre-reponse"  className="justify-content-around titre">
            <Col xs="auto" md="auto" data-aos="zoom-in"  data-aos-duration="2000">
                Votre Réponse
            </Col>
        </Row>
        <Form onSubmit={onSubmitForm} id="form">
            <Row className="row-form justify-content-center">
                <Col md={6} sm={6}>
                    <FloatingLabel label="Prénom" className="">
                        <Form.Control type="text" onChange={onChangeForm} placeholder="Prénom" id="prenomFamille" required></Form.Control>
                    </FloatingLabel>
                </Col>
            {/* </Row>
            <Row className="row-form justify-content-center"> */}
                <Col md={6}  sm={6}>
                    <FloatingLabel label="Nom" className="">
                        <Form.Control type="text" onChange={onChangeForm} placeholder="Nom" id="nomFamille"  required></Form.Control>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className="row-form justify-content-center">
                <Col md={6}  sm={6} >
                    <FloatingLabel label="Numéro de téléphone" className="">
                        <Form.Control type="tel" onChange={onChangeForm} placeholder="Numéro de téléphone" id="numeroFamille"  required></Form.Control>
                    </FloatingLabel>
                </Col>
            {/* </Row>
            <Row className="row-form justify-content-center"> */}
                <Col md={6}  sm={6}>
                    <FloatingLabel label="Email" className="">
                        <Form.Control type="email" onChange={onChangeForm} placeholder="Email"id="mailFamille"  required></Form.Control>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className="row-form justify-content-around">
                <Col md="auto" xs="auto">
                    Assisteront au henné :
                </Col>
            </Row>
            <Row className="row-form justify-content-around">
                <Col md="auto" xs="auto">
                    <Form.Check inline type="radio" onChange={onChangeForm} name="presenceHenne" id="presenceHenne" label="Oui" value={1}defaultChecked="1"></Form.Check>
                    <Form.Check inline type="radio" onChange={onChangeForm} name="presenceHenne" id="presenceHenne" label="Non" value={0}></Form.Check>
                </Col>
            </Row>
            {/* {showHenne === 1 ?  */}
            <>
                <Row className="row-form justify-content-center">
                    <Col md={6} sm={12}>
                        <FloatingLabel label="Nombres d'adultes" className="">
                            <Form.Control type="number" onChange={onChangeForm} placeholder="Nombres d'adultes" id="nombreAdulteHenne" disabled={!showHenne} required={showHenne}></Form.Control>
                        </FloatingLabel>
                    </Col>
                {/* </Row>
                <Row className="row-form justify-content-center"> */}
                    <Col md={6}  sm={12}>
                        <FloatingLabel  label="Nombres d'enfants" className="">
                            <Form.Control type="number" onChange={onChangeForm} placeholder="Nombres d'enfants" id="nombreEnfantHenne" disabled={!showHenne} required={showHenne}></Form.Control>
                        </FloatingLabel>
                    </Col>
                </Row> 
                
                {/* <Row className="row-form justify-content-center">
                    <Col md={12}  sm={12}>
                        <FloatingLabel label="Nombres de places dans le car" className="">
                            <Form.Control type="number" onChange={onChangeForm} placeholder="Nombres de places dans le car" disabled={!showHenne} required={showHenne} id="nombreCarHenne"></Form.Control>
                        </FloatingLabel>
                    </Col>
                </Row> */}
            </>
            {/* </> : <> <Row className="row-form justify-content-center">
                    <Col md={6} sm={12}>
                        <FloatingLabel label="Nombres d'adultes" className="">
                            <Form.Control type="text" onChange={onChangeForm} placeholder="Nombres d'adultes" id="nombreAdulteHenne" disabled value={0}></Form.Control>
                        </FloatingLabel>
                    </Col>
                {/* </Row>
                <Row className="row-form justify-content-center"> 
                    <Col md={6}  sm={12}>
                        <FloatingLabel  label="Nombres d'enfants" className="">
                            <Form.Control type="text" onChange={onChangeForm} placeholder="Nombres d'enfants" id="nombreEnfantHenne" disabled value={0}></Form.Control>
                        </FloatingLabel>
                    </Col>
                </Row>
                
                <Row className="row-form justify-content-center">
                    <Col md={12}  sm={12}>
                        <FloatingLabel label="Nombres de places dans le car" className="">
                            <Form.Control type="text" onChange={onChangeForm} placeholder="Nombres de places dans le car" id="nombreCarHenne" disabled value={0}></Form.Control>
                        </FloatingLabel>
                    </Col>
                </Row></> } */}
            <Row className="row-form justify-content-around">
                <Col md="auto" xs="auto">
                    Assisteront à la soirée :
                </Col>
            </Row>
            <Row className="row-form justify-content-around">
                <Col md="auto" xs="auto">
                    <Form.Check inline type="radio" onChange={onChangeForm} name="presenceSoiree" id="presenceSoiree" label="Oui" value={1} defaultChecked="1"></Form.Check>
                    <Form.Check inline type="radio" onChange={onChangeForm} name="presenceSoiree" id="presenceSoiree" label="Non" value={0}></Form.Check>
                </Col>
            </Row>
            {/* {showSoiree == '1' ?  */}
            <>
                <Row className="row-form justify-content-center" >
                    <Col md={6} sm={12}>
                        <FloatingLabel label="Nombres d'adultes" className="">
                            <Form.Control type="number" onChange={onChangeForm} placeholder="Nombres d'adultes" id="nombreAdulteSoiree" disabled={!showSoiree} required={showSoiree}></Form.Control>
                        </FloatingLabel>
                    </Col>
                {/* </Row>
                <Row className="row-form justify-content-center"> */}
                    <Col md={6}  sm={12}>
                        <FloatingLabel label="Nombres d'enfants" className="">
                            <Form.Control type="number" onChange={onChangeForm} placeholder="Nombres d'enfants" id="nombreEnfantSoiree" disabled={!showSoiree} required={showSoiree}></Form.Control>
                        </FloatingLabel>
                    </Col>
                </Row> 
                <Row className="row-form justify-content-center">
                    <Col md={12}  sm={12}>
                        <FloatingLabel label="Nombres de places dans le car" className="">
                            <Form.Control type="number" onChange={onChangeForm} placeholder="Nombres de places dans le car" id="nombreCarSoiree" required={showSoiree} disabled={!showSoiree}></Form.Control>
                        </FloatingLabel>
                    </Col>
                </Row> 
            </>
            {/*: <>
            <Row className="row-form justify-content-center" >
                <Col md={6} sm={12}>
                    <FloatingLabel label="Nombres d'adultes" className="">
                        <Form.Control type="number" onChange={onChangeForm} placeholder="Nombres d'adultes" id="nombreAdulteSoiree"  value={0}></Form.Control>
                    </FloatingLabel>
                </Col>
            {/* </Row>
            <Row className="row-form justify-content-center"> 
                <Col md={6}  sm={12}>
                    <FloatingLabel label="Nombres d'enfants" className="">
                        <Form.Control type="number" onChange={onChangeForm} placeholder="Nombres d'enfants" id="nombreEnfantSoiree" disabled value={0}></Form.Control>
                    </FloatingLabel>
                </Col>
            </Row> 
            <Row className="row-form justify-content-center">
                <Col md={12}  sm={12}>
                    <FloatingLabel label="Nombres de places dans le car" className="">
                        <Form.Control type="number" onChange={onChangeForm} placeholder="Nombres de places dans le car" id="nombreCarSoiree" disabled value={0}></Form.Control>
                    </FloatingLabel>
                </Col>
            </Row> 
        </>}*/}
                
                <Row className="justify-content-center">
                    <Col md="auto" xs="auto">
                        <Button type="submit">Envoyer</Button>
                    </Col>
                </Row>
        </Form><br></br>
        <p style={{fontSize:8+"px", textAlign:"center", color:"gray", fontStyle:"italic"}}>Site web développé par <a style={{color:"lightslategray"}} href="https://www.linkedin.com/in/mbissor/">Melvin Bissor</a></p>
        <Modal show={showMessage}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body>
                <p style= {{fontStyle:"italic", color:"rgb(227, 161, 161)", textAlign:"center"}}>
                    {message}
                </p>
                {/* <h4>Centered Modal</h4> */}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=>{setShowMessage(false)}}>Close</Button>
            </Modal.Footer>
            </Modal>
        </>
    )
}

export default Formulaire