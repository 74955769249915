import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import Aos from "aos"

import logo from "../../asset/logo_candan_red.png"

Aos.init()
const Henne = () => {

    return (<section className="">

        <Row id="henne"  className="justify-content-around titre">
            <Col md="auto" xs="auto" data-aos="zoom-in"  data-aos-duration="1000">
                <div>Le Henné</div>
            </Col>
        </Row>
        <Row  className="justify-content-around messsage">
            <Col md="auto" xs="auto" data-aos="zoom-in" style={{textAlign:"center"}} data-aos-duration="1000">
                <p className="maman">
                    Estelle & Nathalie
                </p>
                <p className="">
                    Seraient heureuses
                    <br></br>
                    de vous recevoir à l'occasion de la soirée orientale
                </p>
            </Col>
        </Row>
        <br></br>
        
        <br></br>
        <Row className="justify-content-around infos" >
            <Col md="auto" xs="auto" data-aos="zoom-in"  data-aos-duration="1000">
                <p>Le Lundi 26 août 2024 à 18h45</p>
            </Col>
        </Row>
        {/* <Row className="justify-content-around" >
            <Col md="auto"xs="auto" data-aos="zoom-in"  data-aos-duration="1000">
            <p>au merveilleux endroit</p>
            </Col>
        </Row> */}
        <Row  className="justify-content-around">
            <Col md="auto" xs="auto" data-aos="zoom-in" style={{textAlign:"center"}}data-aos-duration="1000">
                <span className="infos">Plage Les Canailles Nice </span><br></br>
                197 Promenade des Anglais <br></br>
                06200 Nice
            </Col>
        </Row>
        {/* 
        <Row  className="justify-content-around">
        <Col md="auto" xs="auto" data-aos="zoom-in"  data-aos-duration="1000">
        <p>13340 Rognac</p>
        </Col>
    </Row> */}
        <br></br>
        <br></br>
        <Row className="justify-content-around " style={{textAlign:"center"}}>
            <Col md="auto" xs="auto"  data-aos="zoom-in" data-aos-delay="5s">
                <p>
                    {/* Des cars seront mis à votre dispositon pour vous rendre sur le lieu de la réception. */}
                </p>
                <p>

                    {/* Départ des cars à <strong>16h30
                    </strong> du <strong><a className="itineraire" href="https://www.waze.com/fr/live-map/directions/fr/provence-alpes-cote-dazur/villefranche-sur-mer/domaine-du-mont-leuze?navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location&to=place.ChIJi5Hx3drEzRIR-VoPoaGGJpc&from=place.ChIJF-MZV__RzRIRJPLBkxDAUpo">Radisson Hotel Nice Airport
                    </a></strong> et du <strong><a className="itineraire" href="https://www.waze.com/fr/live-map/directions/fr/provence-alpes-cote-dazur/villefranche-sur-mer/domaine-du-mont-leuze?navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location&to=place.ChIJi5Hx3drEzRIR-VoPoaGGJpc&from=place.ChIJPTFgnf3QzRIR-wvM2KkdDqE">Nemea Appart'Hotel Nice Riviera.
                        </a></strong> */}
                </p>
            </Col>
        </Row>
        <Row className="justify-content-around logo">
            <img src={logo} alt="logo" style={{ width: 50 + "px", height: "auto" }}  data-aos="zoom-in"  data-aos-duration="1000"/>
        </Row>
        <Row  className="justify-content-around solenel-code">
            <Col md="auto" xs="auto" data-aos="zoom-in"  data-aos-duration="1000">
             {/*    <p>Dress code : préparez vos plus belles tenues blanches !</p> */}
                {/* <p>Attention: tenue blanche exigée !</p>
                <p>Tenue blanche exigée !</p> */}
            </Col>
        </Row>
        
        
        <Row className="justify-content-around">
            <Col className="solenel-honneur" md="auto"  xs="auto" data-aos="zoom-in"  data-aos-duration="1000">
                <Button  className="itinéraire" href="https://www.waze.com/fr/live-map/directions/fr/provence-alpes-cote-dazur/nice/plage-les-canailles-nice?utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location&to=place.ChIJ0U982NvRzRIRoFqTBC1Dm6g">Itinéraire</Button>
            </Col>
        </Row>

    </section>)
}

export default Henne