import { Button, Col, Row } from "react-bootstrap"
import React from "react"
import "../Information.css"

import logo from "../../asset/logo_candan_red.png"

const Mairie = () => {
    return(
        <section className="">
        <Row id="mairie"  className="justify-content-around titre">
            <Col md="auto" xs="auto" data-aos="zoom-in"  data-aos-duration="2000">
                <div> La Mairie</div>
            </Col>
        </Row>
        <Row  className="justify-content-around">
            <Col md="auto" xs="auto" data-aos="zoom-in"  data-aos-duration="2000">
                <h3 className="marie">Eden & Ilan</h3>
            </Col>
        </Row>
        <Row  className="justify-content-around">
            <Col md="auto" xs="auto"  data-aos="zoom-in"  data-aos-duration="1000">
                <p>se diront</p>
            </Col>
        </Row>
        <Row  className="justify-content-around">
            <Col md="auto" xs="auto" className="importante-oui" data-aos="zoom-in"  data-aos-duration="2000">
                <p>"Oui"</p>
            </Col>
        </Row>
        <Row className="justify-content-around infos" >
            <Col md="auto" data-aos="zoom-in"  data-aos-duration="1500">
                <p>Le Jeudi 18 Juillet 2024 à 15h</p>
            </Col>
        </Row>
        <br></br>
        <Row  className="justify-content-around infos">
            <Col md="auto" style={{textAlign:"center"}} xs="auto" data-aos="zoom-in"  data-aos-duration="1500">
                <span className="">A la Mairie de Saint-Mandé</span>
            </Col>
        </Row>
        <Row className="justify-content-around ">
            <Col md="auto"  xs="auto" data-aos="zoom-in"  data-aos-duration="1500">
                <p>10 Place Charles Digeon, 94160 Saint-Mandé</p>
            </Col>
        </Row>
        <br></br>
        
        <Row className="justify-content-around logo">
            <img src={logo} alt="logo" style={{ width: 50 + "px", height: "auto" }}  data-aos="zoom-in"  data-aos-duration="1000"/>
        </Row>
        <br></br>
        <Row className="justify-content-around">
            <Col className="solenel-honneur" md="auto"  xs="auto" data-aos="zoom-in"  data-aos-duration="1000">
                <p>Un vin d'honneur suivra le mariage civil</p><br></br>
                {/* <Button  className="itinéraire" href="https://www.waze.com/fr/live-map/directions/fr/idf/saint-mande/mairie-de-saint-mande?navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location&to=place.ChIJKzuBc49y5kcRDhokKNhpwwU">Itinéraire</Button> */}
            </Col>
        </Row>
        </section>
    )

}

export default Mairie