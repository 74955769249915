import React,{ useState } from "react"
import { Button, Col, Modal, ModalBody, Row } from "react-bootstrap"

const Hebergement = () => {

    const [radisson, setRadisson] = useState(true)
    const [nemea, setNemea] = useState(true)      
   
    // const axios = require("axios")

    const showMapRadisson = () => {
        if(radisson) {
            setRadisson(false)
            // setNemea(true)
        }else{
            setRadisson(true)
        }
    }
    const showMapNemea = () => {
        if(nemea) {
            // setRadisson(true)
            setNemea(false)
        }else{
            setNemea(true)
        }
    }
    return(
        <section>
            <Row id="hebergement"  className="justify-content-around titre">
                <Col md="auto" xs="auto" data-aos="zoom-in"  data-aos-duration="1000">
                    <div>Logements</div>
                </Col>
            </Row>
            <Row   className="justify-content-around infos">
                <Col md="auto" xs="auto" data-aos="zoom-in"  data-aos-duration="1000">
                    <Row>
                        Radisson Hotel Nice Airport
                    </Row>
                    <Row>
                        <Col md={4}>
                            <img style={{width:100+"%", height:100+"%",margin:5+"px"}}src="https://lh5.googleusercontent.com/p/AF1QipMMeXd30zEGUUmB8O9KFsEtRnOQ2utSuJghYHDS=w253-h140-k-no"/>
                        </Col>
                        <Col md={4}>
                            <img style={{width:100+"%", height:100+"%",margin:5+"px"}}src="https://lh5.googleusercontent.com/p/AF1QipNFOkHYx0mJam3Rpdenon3oSIC0wtsunXo1mb6r=w253-h171-k-no"/>
                        </Col>
                        <Col md={2}>
                            <Button className="itineraire" style={{width:100+"%"}} onClick={()=>{showMapRadisson()}}>
                                Voir plan
                            </Button><br></br>
                            <Button className="itineraire" style={{width:100+"%"}} href="https://www.booking.com/hotel/fr/park-inn-nice.fr.html?aid=304142&label=gen173bo-1FCAEiBWhvdGVsKIICOOgHSA1YA2hNiAECmAENuAEHyAEN2AEB6AEB-AEDiAIBmAICqAIDuAK3z8ueBsACAdICJGM2YTc0YTU5LTEwYjItNDliNC05ZTY1LTBhNDBjYTlmYzM1ONgCBeACAQ&sid=cf68ededbfc05b5f563176248a04d07f&all_sr_blocks=5805824_94107019_2_42_0;checkin=2024-08-25;checkout=2024-08-29;dest_id=58058;dest_type=hotel;dist=0;group_adults=2;group_children=0;hapos=1;highlighted_blocks=5805824_94107019_2_42_0;hpos=1;matching_block_id=5805824_94107019_2_42_0;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;sr_pri_blocks=5805824_94107019_2_42_0__70560;srepoch=1704137245;srpvid=9a3388c1f8240395;type=total;ucfs=1&#hotelTmpl">
                                Réservation
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row   className="justify-content-around infos">
                <Col md="auto" xs="auto" data-aos="zoom-in"  data-aos-duration="1000">
                    <Row>
                        Nemea Appart'Hotel Nice Riviera
                    </Row>
                    <Row>
                        <Col md={4}>
                            <img style={{width:100+"%", height:100+"%",margin:5+"px"}}src="https://lh6.googleusercontent.com/proxy/auKDiOz2b5HGKeDIxlENbdDk9Tk_rFfQDRVULqgvECwMR2qPlQFfPpJhJlHQYswWzOKbSzovcSpouBVvCYwLWEOSDaNFWlpmQBRKbGnLRbtqQOkrSLDuQuc9csTw6ZC3tARKcaynHlW_h54mQZ33fr8s-lCmQA=w253-h168-k-no"/>
                        </Col>
                        <Col md={4}>
                            <img style={{width:100+"%",height:100+"%", margin:5+"px"}}src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/512393287.jpg?k=c7c323bd2903a0fc41337ea999a00f198231fb1b00071d384ff176bd8bb40b26&o=&hp=1"/>
                        </Col>
                        <Col md={2}>
                            <Button className="itineraire" style={{width:100+"%"}} onClick={()=>{showMapNemea()}}>
                                Voir plan
                            </Button><br></br>
                            <Button className="itineraire" style={{width:100+"%"}} href="https://www.residence-nemea.com/ete/france/provence-alpes-cote-d-azur/alpes-maritimes/nice.html">
                                Réservation
                            </Button>
                        </Col>
                    </Row>
                </Col>
                
            </Row>
            
            <Modal show={!radisson} onHide={showMapRadisson} centered>
                <ModalBody>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.081800318391!2d7.206174675710941!3d43.66726855152148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cdd1ff5719e317%3A0x9a52c01093c1f224!2sRadisson%20Hotel%20Nice%20Airport!5e0!3m2!1sfr!2sfr!4v1704136581054!5m2!1sfr!2sfr"  style={{border:0, width:100+"%", height:50+"vh"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2904.026086497812!2d5.358209251092513!3d43.292775383577535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9c0daebf39b65%3A0x287e5ebbec71ef5b!2sSofitel%20Marseille%20Vieux-Port!5e0!3m2!1sfr!2sfr!4v1673824031125!5m2!1sfr!2sfr"  style={{border:0, width:100+"%", height:50+"vh"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                </ModalBody>
            </Modal>
            <Modal show={!nemea} onHide={showMapNemea} centered>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2885.9264840086425!2d7.220191875711077!3d43.67049875131248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cdd0fd9d60313d%3A0xa10e1da9d8cc0bfb!2sNemea%20Appart&#39;Hotel%20Nice%20Riviera!5e0!3m2!1sfr!2sfr!4v1704136718630!5m2!1sfr!2sfr" style={{border:0, width:100+"%", height:50+"vh"}}allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2904.0348296704046!2d5.358064451092499!3d43.29259228358929!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9c0da67958805%3A0x990550d31f080bcb!2sH%C3%B4tel%20Novotel%20Marseille%20Vieux%20Port!5e0!3m2!1sfr!2sfr!4v1673824704613!5m2!1sfr!2sfr" style={{border:0, width:100+"%", height:50+"vh"}}allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            </Modal>
           {/*  <Modal show={!newHotel} onHide={showMapNewHotel} centered>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2904.062439206227!2d5.357867015486059!3d43.292014079135384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9c0da6115c71b%3A0x8ea0f111c0bdac87!2sNew%20Hotel%20Of%20Marseille!5e0!3m2!1sfr!2sfr!4v1674771966023!5m2!1sfr!2sfr"style={{border:0, width:100+"%", height:50+"vh"}}allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </Modal> */}
        </section>
    )
}

export default Hebergement