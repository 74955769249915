import { Button, Col, Row } from "react-bootstrap"
import React from "react"
import train from "../../asset/trainIcon.png"

import avion from "./../../asset/avionIcon.png"

const Transport = () => {

    return(

        <section>
            <Row id="transport"  className="justify-content-around titre">
                <Col md="auto" xs="auto" data-aos="zoom-in"  data-aos-duration="1000">
                    <div>Transport</div>
                </Col>
            </Row>
            <Row  md={2} className="justify-content-around infos">
                <Col md={6} xs="auto" data-aos="zoom-in"  data-aos-duration="1000" className="zoom">
                    <Row>
                        <Col md={4} xs={4}>
                            <img src={train} alt="Train" style={{width:100+"%"}}/>
                        </Col>
                        <Col md={8} xs= {8}>
                            <p>
                                Gare de Nice
                            </p>
                            <p>
                                <Button href="https://www.sncf-connect.com/">
                                    Réservation
                                </Button>
                            </p>
                            <p className="solenel-honneur">
                                Choisir un départ avant 10h pour ne pas manquer le henné
                            </p>
                        </Col>

                    </Row>
                </Col>
                
                <Col md={6} xs="auto" data-aos="zoom-in"  data-aos-duration="1000"className="zoom">
                    <Row>
                        <Col md={4} xs={4}>
                            <img src={avion} alt="Train" style={{width:100+"%"}}/>
                        </Col>
                        <Col md={8} xs={8}>
                            <p>
                                Aéroport de Nice
                            </p>
                            
                            <p>
                                <Button href="https://wwws.airfrance.fr/search/advanced?pax=1:0:0:0:0:0:0:0&cabinClass=ECONOMY&activeConnection=0&connections=PAR:C%3ENCE:A-NCE:A%3EPAR:C&bookingFlow=LEISURE">
                                    Réservation
                                </Button>
                            </p>
                        </Col>

                    </Row>
                    {/* <img href={avion}/> */}
                    {/* <Row>
                        Train<br></br>
                        <p>Gare Marseille-Saint-Charles</p>
                        <p className="">Prendre un train avant 11h pour ne pas rater le henné</p>
                    </Row> */}
                </Col>
            </Row>
        </section>
    )
}

export default Transport