
import React, { useState } from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import Formulaire from './Formulaire/Formulaire';
import Information from './Information/Information';
import './App.css'
import Header from './Header/Header';
import Menu from './Menu/Menu';
import Mairie from "./Information/mairie/Mairie";
import Henne from "./Information/henne/Henne";
import Soiree from "./Information/soiree/Soiree";

function App() {
  return (
    <div className="App">
      {/* <header className="App-header"> */}
      <Container fluid>
        {/* <Col>
          <Menu/>
          <Header/>
          <Mairie/>
          <Henne/>
          <Soiree/>

        
        </Col> */}
        <Col md={12}  xs={"auto"} sticky="top" >
          <Row className="navbar-style-wedding  justify-content-between">
            <Col md={12}  xs={12} sm={12} >
              <Menu/> 
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={12} xs={"auto"} >
              <Row className="section justify-content-center">
                <Col md={8} xs={"auto"} >
                  <Header/>
                </Col>
              </Row>
              <Row className="section justify-content-center">
                <Col md={8} xs={"auto"} >
                  <Information/>
                </Col>
              </Row>
              <Row className="section justify-content-center">
                <Col md={8} xs={"auto"} >
                  <Formulaire/>
                </Col>
              </Row>
            </Col>       
          </Row>
        </Col>       
      </Container>
      {/* </header> */}
      <script src="https://unpkg.com/react-dom/umd/react-dom.production.min.js" crossorigin></script>
      <script src="https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js" crossorigin></script>

    </div>
  );
}

export default App;
